import React from "react";
import Container from "@/components/Container";
import GatsbyLink from "@/components/GatsbyLink";
import { FOOTER, COPYRIGHT } from "../../config";
import Logo from "../images/juxt-logo.svg";

export default function Footer() {
  return (
    <footer>
      
    </footer>
  );
}
