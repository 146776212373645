module.exports = {
  url: "https://notes.desktopofsamuel.com",
  pathPrefix: "",
  SITE_TITLE: "faiz.codes",
  SITE_SUBTITLE: "ui/ux designer/developer",
  SITE_DESCRIPTION:
    "faiz.codes | ui/ux designer/developer",
  KEYWORDS: "faiz, codes",
  HERO_TITLE:
    "Free Gatsby starter template to host your own digital garden for free, created for Obsidian knowledge base.",
  HERO_SUBTITLE: "Designed by JUXT Design",
  COPYRIGHT: "JUXT Design",
  LOCALE: "en",
  POST_PER_PAGE: 6,
  MENU: [
    {
      label: "About",
      path: "/about/",
    },
    {
      label: "Topics",
      path: "/topics/",
    },
  ],
  FOOTER: [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "About",
      path: "/about/",
    },
    {
      label: "Changelog",
      path: "/changelog/",
    },
    {
      label: "Github",
      path: "https://github.com/juxtdesigncc/gatsby-starter-obsidian-garden",
    },
  ],
  AUTHOR: {
    NAME: "JUXT Design",
    TWITTER: "@juxtdesigncc",
  },
};
